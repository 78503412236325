<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="poViewItemDialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-dialog v-model="dialog" width="1200" scrollable>
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            Edit Purchase Order
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text style="max-height: 900px">
            <v-form @submit.prevent ref="Formref">
            <v-row class="mt-6">
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="poData.SupplierID"
                  :items="supplier"
                  item-text="CompanyName"
                  item-value="SupplierID"
                  :rules="[formRules.required]"
                  label="Supplier"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="poData.podID"
                  :items="pod"
                  item-text="podDescription"
                  item-value="podID"
                  :rules="[formRules.required]"
                  label="Place of Delivery"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="poData.dodID"
                  :items="dod"
                  item-text="dodDescription"
                  item-value="dodID"
                  :rules="[formRules.required]"
                  label="Date of Delivery"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="poData.dtID"
                  :items="deliveryterm"
                  item-text="dtDescription"
                  item-value="dtID"
                  :rules="[formRules.required]"
                  label="Delivery Term"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="poData.mopID"
                  :items="mop"
                  item-text="mopDescription"
                  item-value="mopID"
                  :rules="[formRules.required]"
                  label="Mode of Procurement"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="poData.ptID"
                  :items="paymentterm"
                  item-text="ptDescription"
                  item-value="ptID"
                  :rules="[formRules.required]"
                  label="Payment Term"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            </v-form>
            <v-row class="">
              <v-col cols="12">
                <v-data-table
                  :loading="loading"
                  :headers="headers2"
                  :items="itemList"
                  :options.sync="options"
                  :server-items-length="totalCount"
                  :footer-props="footerProps"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <span class="green--text text--darken-2">List Of Items</span>
                      <v-spacer></v-spacer>
                      <v-dialog v-model="dialog2" persistent max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="amber darken-2"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!EnableGrandtotal"
                          @click="editGrandTotal(poData.gtotal)"
                        >
                          Edit Grand Total
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Edit Grand Total
                        </v-card-title>
                        <v-card-text>
                          <v-text-field
                            dense
                            v-model="poData.gtotal"

                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            Continue
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.ppmpItemID">
                        <td>
                          <v-edit-dialog
                            large
                            :return-value.sync="item.ItemName"
                          >
                            {{ item.ItemName }}
                            <template v-slot:input>
                              <v-textarea v-model="item.ItemName">
                              </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog
                            large
                            :return-value.sync="item.ItemDescription"
                          >
                            {{ item.ItemDescription }}
                            <template v-slot:input>
                              <v-textarea v-model="item.ItemDescription">
                              </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>{{ item.UnitMeasureDescription }}</td>
                        <td align="center">
                          {{getUCDescription(item.UnitConversionID)}}
                        </td>
                        <td align="center">
                          <v-edit-dialog large :return-value.sync="item.Qty">
                            {{ item.Qty }}
                            <template v-slot:input>
                              <v-text-field v-model="item.Qty" type="number">
                              </v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td align="right">
                          <v-edit-dialog
                            large
                            :return-value.sync="item.UnitCost"
                          >
                            <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                            <template v-slot:input>
                              <v-textarea v-model="item.UnitCost"> </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td align="right">
                          <span class="text-no-wrap">₱
                          {{
                            formatPrice(
                              parseFloat(item.UnitCost) * parseFloat(item.Qty)
                            )
                          }}
                          </span>
                        </td>
                        <td align="center">
                          {{parseFloat(item.Qty) - parseFloat(item.IARQTY)}}
                        </td>
                        <td align="center">
                          <v-badge
                            bordered
                            color="error"
                            icon="mdi-check-bold"
                            overlap
                            :value="item.conversion_data && item.conversion_data.UnitMeasureID != null"
                          >
                          <v-btn color="red darken-2" small class="pa-0 white--text" :disabled="item.unitconversion.length <= 1" @click="convertItem(item)"><v-icon>mdi-cached</v-icon></v-btn>
                          </v-badge>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
              <v-col>
                <v-card>
              <v-textarea
                color="green"
                dense
                v-model="poData.note"
                label="Notes:"
                counter
                maxlength="2000"
                class="pa-5 pt-8"
              ></v-textarea>
            </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <span><b>Overall Amount (PO/PR):</b>  <span :style="po_total > pr_total ? 'color:red;' : 'color:green;'">{{formatPrice(po_total)}} / {{formatPrice(pr_total)}}</span></span>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-2" dark @click="updatePO()" :loading="editingPO">
              <v-icon>mdi-pencil</v-icon>
              Update PO
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    
      <v-dialog v-model="dialogUnpublish" width="1000">
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            Unpublish PO Item
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialogUnpublish = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12">
                <v-data-table
                  :loading="loading"
                  v-model="selectedPOItemList"
                  :headers="headers"
                  :items="itemList"
                  item-key="POItemID"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                  show-select
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <span class="green--text text--darken-2">List Of Items</span>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.ppmpItemID">
                        <td>
                        <v-checkbox
                          v-model="selectedPOItemList"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          hide-details
                        />
                        </td>
                        <td>{{ item.ItemName }}</td>
                        <td>
                            {{ item.ItemDescription }}
                        </td>
                        <td>{{ item.UnitMeasureDescription }}</td>
                        <td>
                            {{ item.Qty }}
                        </td>
                        <td>
                            ₱ {{ formatPrice(item.UnitCost) }}
                        </td>
                        <td>
                          ₱
                          {{
                            formatPrice(
                              parseFloat(item.UnitCost) * parseFloat(item.Qty)
                            )
                          }}
                        </td>
                        <td align="center">
                          {{parseFloat(item.Qty) - parseFloat(item.IARQTY)}}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" text @click="dialogUnpublish = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" text @click="showUnpublishConfrirmation()" :disabled="selectedPOItemList.length==0">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card>
        <v-toolbar dark max-height="65" color="green darken-2">
          <v-toolbar-title>{{ poData.POCode }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="poData.FinanceStatusID == 7"
              >
              <span class="text-no-wrap">Funds Availability</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(poData.FinanceApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="poData.PresidentStatusID == 7"
                step="2"
                class="pa-3 pr-md-16"
              >
                <span class="text-no-wrap">College President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(poData.PresidentApprovedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <v-alert
          border="left"
          type="error"
          v-if="poData.FinanceRemarks != '' || poData.PresidentRemarks != ''"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <div v-if="poData.FinanceRemarks"> <strong>Finance:</strong> {{ poData.FinanceRemarks }}</div>
          <div v-if="poData.PresidentRemarks"> <strong>College President:</strong> {{ poData.PresidentRemarks }}</div>
        </v-alert>
        <!-----Header----->
        <v-card-text style="max-height: 900px;">
        <div>
          <v-row class="mt-4">
            <v-col cols="12" class="d-flex justify-center">
              <v-subheader class="font-weight-bold text-h6"
                >Purchase Order</v-subheader
              >
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">PO no.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.POCode }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Supplier:</v-subheader>
            </v-col>
           <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.CompanyName }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Place of Delivery:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.podDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Supplier Address:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.CompanyAddress }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Date of Delivery:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.dodDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">TIN:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.TIN }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Delivery Term:</v-subheader>
            </v-col>
           <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.dtDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Mode of Procurement:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.mopDescription }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Payment Term:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.ptDescription }}</v-subheader>
            </v-col>
          </v-row>
        </div>

        <v-row class="my-4">
          <v-col lg="12" class="py-4">
            <v-card class="">
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="itemList"
                :options.sync="options"
                :server-items-length="totalCount"
                :footer-props="footerProps"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="grey darken-2 mr-2"
                      dark
                      small
                      @click="editpo()"
                      class="mr-2"
                      v-if="currentRoleID == 5"
                    >
                      <v-icon>mdi-clipboard-edit</v-icon>
                      Edit PO
                    </v-btn>
                    <vue-json-to-csv 
                    :json-data="itemListForCSV"
                    :labels="{ 
                      ItemName: { title: 'Item Name' },
                      ItemDescription: { title: 'Item Description' },
                      UnitMeasureDescription: { title: 'Unit Measure' },
                      Qty: { title: 'Quantity' },
                      UnitCost: { title: 'Unit Cost' },
                      Amount: { title: 'Amount' },
                      }"
                    :csv-title="poData.POCode || poData.POCodeTemp"
                    :disabled="itemListForCSV.length == 0"
                    >
                      <v-btn
                        outlined
                        small
                        color="green darken-2"
                        dark
                        class="mr-2"
                        :loading="loadingCSV"
                      >
                        <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                        CSV
                      </v-btn>
                    </vue-json-to-csv>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="print(poData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      small
                      v-if="$store.state.user.roleID == 5 && itemList.length > 0 && unpublishBtn"
                      @click="unpublish(poData.POID)"
                    >
                    <v-icon class="mr-1">mdi-close-box-outline</v-icon>
                      Unpublish
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:[`item.QtyRemaining`]="{ item }">
                  {{parseFloat(item.Qty) - parseFloat(item.IARQTY)}}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text>
              <span class="text-h6">Notes: </span>
              <span v-if="poData.note">{{poData.note}}</span>
              <i v-else>No notes</i>
              <!-- <v-textarea
                color="green"
                dense
                v-model="poData.note"
                label="Notes:"
                counter
                maxlength="2000"
                class="pa-5 pt-8"
              ></v-textarea> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
      <v-btn fab dark large color="header" @click="viewRemarks()" fixed right bottom>
        <v-badge
          color="red darken-2"
          :content="unseenMsgs"
          :value="unseenMsgs"
        >
          <v-icon dark>mdi-email</v-icon>
        </v-badge>
    </v-btn>
    </v-dialog>
    <v-dialog v-model="dialogConfirmUnpublish" max-width="500px">
      <v-card>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="12">
              <v-icon dense x-large color="red darken-2" class="mt-4"
                >mdi-help-circle-outline</v-icon
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-title class="text-h6 d-flex justify-center"
          >Do you want to Unpublish this PO?</v-card-title
        >
        <v-card-actions class="mt-4">
          <v-row justify="center">
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogConfirmUnpublish = false"
                >Cancel</v-btn
              >
            </v-col>
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="green darken-2" text @click="confirmUnpublish()"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="convertDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Unit Conversion</v-card-title>
        <div class="mx-6 mt-3">
          <v-form @submit.prevent ref="UCFormRef">
          <v-row>
            <v-col cols="12">
              <v-autocomplete hide-details v-model="convert_data.UnitConversionID" @change="convert()" :items="convert_data.unitconversion" class="pa-0 mr-1" item-text="description" item-value="id" color="header" outlined dense></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-card
                class="pa-3"
                color="grey lighten-3"
                flat
              >
              <v-card-text class="pa-0 ma-0">
              <v-row no-gutters v-if="convert_data">
                <v-col cols="12" sm="3">
                  <strong>Item: </strong>
                </v-col>
                <v-col cols="12" sm="9">
                  {{convert_data.ItemName}}
                </v-col>
              </v-row>
              <v-row no-gutters v-if="convert_data">
                <v-col cols="12" sm="3">
                  <strong>Amount: </strong>
                </v-col>
                <v-col cols="12" sm="9">
                  {{formatPrice(convert_data.Qty * convert_data.UnitCost)}}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <strong>Conversion: </strong>
                </v-col>
                <v-col cols="12" sm="9">
                  {{getUCDescription(convert_data.UnitConversionID)}}
                </v-col>
              </v-row>
              </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="convert_item.Qty"
                :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                @keyup="changeUnit()"
                type="number"
                label="Quantity"
                color="header"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="convert_item.UnitCost"
                :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                @keyup="changeUnit()"
                type="number"
                label="UnitCost"
                color="header"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="convert_item.Amount"
                :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed, checkAmount(parseFloat((convert_data.Qty * convert_data.UnitCost)), parseFloat(convert_item.Amount))]"
                type="number"
                label="Amount"
                color="header"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
          </v-form>
        </div>
        <v-card-actions class="mt-5 pb-4">
          <v-spacer></v-spacer>
          <!-- <v-btn color="grey darken-2" outlined @click="closeConvertDialog()">Close</v-btn> -->
          <v-btn color="red darken-2 white--text" @click="confirmConvert()" :loading="isLoading"><v-icon class="mr-1">mdi-check-circle</v-icon>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PSURemarks :data="poRemarksData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
<script>
import PSURemarks from '../Utils/PSURemarks.vue'
export default {
  components: {
      PSURemarks
  },
  props: {
    poData: {},
  },
  data: () => ({                                                                                                                       
    tempGrandTotal: null,
    EnableGrandtotal: false,
    dialog: false,
    dialog2: false,
    remarks: null,
    deliveryterm: [],
    supplier: [],
    dod: [],
    mop: [],
    paymentterm: [],
    pod: [],
    headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      { text: "Unit Meas.", value: "UnitMeasureDescription", sortable: false, align: "center" },
      {
        text: "Unit Conversion",
        value: "UCDescription",
        align: "center",
        sortable: false,
      },
      { text: "Qty (Overall)", value: "Qty", sortable: false, align: "center" },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "Qty (Remaining)", value: "QtyRemaining", sortable: false, align: "center" }
    ],
    headers2: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      { text: "Unit Meas.", value: "UnitMeasureDescription", sortable: false, align: "center" },
      {
        text: "Unit Conversion",
        value: "UCDescription",
        align: "center",
        sortable: false,
      },
      { text: "Qty (Overall)", value: "Qty", sortable: false, align: "center" },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "Qty (Remaining)", value: "QtyRemaining", sortable: false, align: "center" },
      { text: "Action", value: "Action", sortable: false, align: "center" },
    ],
    loading: false,
    poViewItemDialog: false,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    options: {},
    isLoading: false,
    itemList: [],
    item: null,
    dialogPending: false,
    dialogApprove: false,
    currentRoleID: null,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    dialogConfirmUnpublish: false,
    toUnpublishPOID: null,
    unpublishBtn: false,
    dialogUnpublish: false,
    selectedPOItemList: [],
    loadingCSV: false,
    itemListForCSV: [],
    poRemarksData: [],
    unseenMsgs: 0,
    uclist: [],
    uclist_filtered: [],
    convert_data: {},
    convert_item: {},
    convertDialog: false,
    po_total: 0,
    pr_total: 0,
    editingPO: false
  }),
  watch: {
    options: {
      handler() {
        if (this.totalCount > 0) {
          this.getItems();
        }
      },
      deep: true,
    },
    poData: {
      handler(data) {
        if (data.POID) {
          this.poViewItemDialog = true;
          if(!this.dialog2){
            this.getItems();
            this.getDataForCSV();
            this.getUnseenMsgs();
            this.getUnitConversion();
          }
        }
      },
      deep: true,
    },
    itemList: {
      handler(data) {
        this.EnableGrandtotal = false;
        data.forEach(item => {
          if (item.UnitCost == 0) {
          this.EnableGrandtotal = true;
        }
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.currentRoleID = this.$store.state.user.roleID;
    this.axiosCall("/get/all/POUtil", "POST", []).then((res) => {
        this.deliveryterm = res.data.data.deliveryterm;
        this.supplier = res.data.data.supplier;
        this.dod = res.data.data.dod;
        this.mop = res.data.data.mop;
        this.paymentterm = res.data.data.paymentterm;
        this.pod = res.data.data.pod;
      });
    this.eventHub.$on('closePSURemarks', ()=>{
          this.poRemarksData = [];
         this.getUnseenMsgs();
        });
  },
  beforeDestroy() {
         this.eventHub.$off('closePSURemarks');
    },
  methods: {
    viewRemarks(){
      this.poRemarksData = {
        id: this.poData.POID,
        DocType: 3,
        RemarksID: this.poData.RemarksID
      };
      if(this.unseenMsgs > 0){
        let data = new FormData();
        data.append("RemarksID", this.poData.RemarksID);
        this.axiosCall("/remarks/seen", "POST", data).then((res) => {
          console.log(res)
          this.unseenMsgs = 0
        });
      }
    },
    getUnseenMsgs(){
      this.unseenMsgs = 0;
        let data = new FormData();
        data.append("RemarksID", this.poData.RemarksID);
        this.axiosCall("/getUnseenMsgs", "POST", data).then((res) => {
        this.unseenMsgs = res.data.data
      });
    },
    approveItem(data) {
      this.item = data;
      this.dialogApprove = true;
    },
    pendingItem(data) {
      console.log(data);
      this.item = data;
      this.dialogPending = true;
    },
    clickCloseMainDialog() {
      this.dialogPending = false;
      this.dialogApprove = false;
      this.totalCount = 0;
      this.poViewItemDialog = false;
      this.eventHub.$emit("closePOItemodal", false);
    },
    editpo() {
        this.dialog = !this.dialog;
    },
    print(data) {
      window.open(this.apiUrl + "/po/report/" + data.POID);
    },
    getItems() {
      this.unpublishBtn = false;
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("POID", this.poData.POID);
      this.axiosCall("/po/ViewItems", "POST", data).then((res) => {
        this.loading = false;
        this.itemList = res.data.data.result;
        this.po_total = res.data.data.po_total;
        this.pr_total = res.data.data.pr_total;
        this.itemList.forEach(item => {
          item.unitconversion = this.getFilteredUClist(item.UnitMeasureID);
          if(item.conversion_data == null){
            item.conversion_data = {
            UnitMeasureID: null,
            Qty: 0,
            UnitCost: 0,
            Amount: 0,
          }
          }
        });
        this.selectedPOItemList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
        this.ppmpUsedAmount = res.data.data.useAmount;
        this.ableToUnpublish();
      });
    },
    updatePO() {
      if (this.$refs.Formref.validate()) {
        this.editingPO = true;
        let data = new FormData();
        data.append("List", JSON.stringify(this.itemList));
        data.append("POID", this.poData.POID);
        data.append("SupplierID", this.poData.SupplierID);
        data.append("ptID", this.poData.ptID);
        data.append("mopID", this.poData.mopID);
        data.append("dodID", this.poData.dodID);
        data.append("dtID", this.poData.dtID);
        data.append("podID", this.poData.podID);
        data.append("gtotal", this.poData.gtotal);
        data.append("ismanual", this.poData.gtotal == this.tempGrandTotal || this.tempGrandTotal == null ? 0 : 1);
        data.append("note", this.poData.note);
        this.axiosCall("/po/update", "POST", data).then((res) => {
          this.editingPO = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Item Successfully Updated!";
            this.fadeAwayMessage.header = "System Message";
            this.dialog = false;
            this.getItems();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    editGrandTotal(amount) {
      if(this.tempGrandTotal == null){
        this.tempGrandTotal = amount;
      }
    },
    ableToUnpublish() {
      this.unpublishBtn = false;
        this.itemList.forEach(item => {
          this.getIARItems(item.POItemID, item.Qty);
        });
    },
     getIARItems(POItemID, Qty) {
      let data = new FormData();
      data.append("POItemID", POItemID);
      this.axiosCall("/po/getIARItemsByPOItemID", "POST", data).then((res) => {
        if (res.data.status) {
          let iarQTY = res.data.data.result.length > 0 ? res.data.data.result[0].Qty : 0;
          if(iarQTY < Qty){
            this.unpublishBtn = true;
          }
        }
      });
    },
    unpublish(POID) {
      this.dialogUnpublish = true;
      this.toUnpublishPOID = POID;
    },
    showUnpublishConfrirmation(){
      this.dialogConfirmUnpublish = true;
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("POID", this.toUnpublishPOID);
      data.append("List", JSON.stringify(this.selectedPOItemList));
      data.append("note", this.selectedPOItemList.length == this.itemList.length ? 'CANCELLED' : this.poData.note);
      console.log(this.selectedPOItemList.length,this.itemList.length);
      this.axiosCall("/po/unpublish", "POST", data).then((res) => {
        if(res.data.status) {
            this.toUnpublishPOID = null;
            this.dialogConfirmUnpublish = false;
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'success';
            this.fadeAwayMessage.message = 'PO successfully unpublished!';
            this.fadeAwayMessage.header = 'System Message';
            this.getItems();
            if(this.itemList.length == 0){
              this.dialogUnpublish = false;
            }
            }else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'error';
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = 'System Message';
            }
      });
    },
    getDataForCSV(){
      this.loadingCSV = true;
      let data = new FormData();
      data.append("POID", this.poData.POID);
      this.axiosCall("/csv/getItemsByPOID", "POST", data).then((res) => {
        this.loadingCSV = false;
        this.itemListForCSV = res.data.data.result;
      });
    },
    closeConvertDialog(){
      this.convertDialog = false;
    },
    getUnitConversion() {
      this.axiosCall("/getAllUnitConversion", "GET").then((res) => {
        this.uclist = res.data.data;
      });
    },
    getFilteredUClist(id){
      let result = [{
        id: 0,
        description: "None"
      }];
      this.uclist.forEach(item => {
        if(id == item.UnitMeasureID_from){
          result.push(item)
        }
      });
      return result;
    },
     convertItem(data){
      this.convert_data = data;
      this.convert_item = data.conversion_data;
      this.convertDialog = true;
     },
    getUCDescription(id){
      let result = "None";
      this.uclist.forEach(item => {
        if(id == item.id){
          result = item.description
        }
      });
      return result;
    },
    convert(){
      let result = null;
      this.uclist.forEach(item => {
        if(this.convert_data.UnitConversionID == item.id){
          result = item
        }
      });
      if(result){
        let factor = parseFloat(result.factor);
        let multiply = parseFloat(result.multiply);
        let UnitMeasureID = result.UnitMeasureID_to;
        this.convert_item.Qty = parseFloat(((parseFloat(this.convert_data.Qty) / factor) * multiply).toFixed(2))
        this.convert_item.UnitCost = parseFloat(((parseFloat(this.convert_data.UnitCost) * factor) * multiply).toFixed(2))
        this.convert_item.Amount = this.convert_item.Qty * this.convert_item.UnitCost;
        this.convert_item.UnitMeasureID = UnitMeasureID;
      }
      else{
        this.convert_item.Qty = 0;
        this.convert_item.UnitCost = 0;
        this.convert_item.Amount = 0;
        this.convert_item.UnitMeasureID = null;
      }
    },
    confirmConvert(){
      if(this.$refs.UCFormRef.validate()){
      this.convert_data.conversion_data = this.convert_item;
      // let temp = JSON.stringify(this.formData.Lists);
      // this.formData.Lists = this.itemList;
      // this.formData.Lists = JSON.parse(temp);
      this.convertDialog = false;
      }
    },
    changeUnit(){
      this.convert_item.Amount = parseFloat(this.convert_item.Qty) * parseFloat(this.convert_item.UnitCost);
    },
    checkAmount(value1, value2){
      return value1 >= value2 || "Amount Exceeded!"
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>